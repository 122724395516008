import React, { FC } from 'react';
import { Typography, Row, Col, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import { IAbout } from '../../models/pages/IAbout';
import ceLogo from './../../../assets/about/CE-Piktogramm.svg';
import handbookLogo from './../../../assets/about/Handbuch-Piktogramm.svg';
import snLogo from './../../../assets/about/SN-Piktogramm.svg';
import manufacturerLogo from './../../../assets/about/Hersteller-Piktogramm.svg';
import './../../scss/pages/About.scss';

const { Title, Text } = Typography;

const About: FC<IAbout> = (): JSX.Element => (
  <div
    className="dtb-content-inner-scroll-container"
    style={{ paddingTop: 20, paddingBottom: 20, overflowY: 'auto' }}
  >
    <Row justify="center" style={{ background: 'none' }}>
      <Col xs={23} sm={23} xl={22} className="about-wrapper">
        <Title level={2} className="text-center ">
          Über uns
        </Title>

        <Col className="content-wrapper dtb-long-text">
          <Text className="display-block margin-bottom-m">
            {`Der Tino DTB ist ein Digitaler Therapiebegleiter für Krebspatienten mit einer oralen Therapie.
          Die App dient als Kommunikationsplattform zwischen dem Patienten und seinem persönlichen medizinischen
          Betreuungsteam. Er hilft Patienten, sich in ihrer Therapie besser zurechtzufinden, den Überblick zu behalten
          und das medizinische Betreuungsteam in Echtzeit über das Befinden zu informieren. `}
          </Text>
          <Text className="display-block margin-bottom-m">
            {`Der Tino DTB ist ein in Deutschland entwickeltes und registriertes Medizinprodukt der Klasse I. Er wird
           unter Einbezug von niedergelassenen Hämatologen / Onkologen, Gynäkologen, Klinikambulanzärzten sowie
           onkologischen Pharmazeuten ständig weiterentwickelt und an die Bedürfnisse der Versorgung angepasst.
           Tino DTB steht dabei für "Therapie IN der Onkologie. Digitaler Therapie-Begleiter“ mit dem Ziel, die
           Patienten und das medizinische Betreuungsteam in bestmöglicher Weise bei der Krebstherapie zu unterstützen.`}
          </Text>
          <Text className="display-block margin-bottom-s">
            {`Die Tino DTB App bietet Krebspatienten folgende unterstützende Funktionen: `}
          </Text>
          <ol className="margin-left-m">
            <li>
              <Text>
                {`Therapieplan: Patienten erhalten – nach den Vorgaben des erstellten Therapieplanes des
              behandelnden Arztes – in der Tino DTB App eine einfache Übersicht über die einzunehmenden
              Medikamente (Therapiemedikation, Supportivmedikation, Bedarfsmedikation und weitere Medikamente)
              sowie eine zeitpunktgenaue Erinnerungsfunktionen zur Einnahme.
          `}
              </Text>
            </li>
            <li>
              <Text>
                {`Informationen: Patienten bekommen in der Tino DTB App speziell aufbereitete und qualitätsgesicherte
               Information zu ihrer Therapie, wie Anwendungs- und Einnahmehinweise sowie praktische Hinweise zur
               Vorbeugung und beim Auftreten von spezifischen Nebenwirkungen.`}
              </Text>
            </li>
            <li>
              <Text>{`Dokumentation: `}</Text>
              <ol type="a" className="margin-left-l">
                <li>
                  <Text>
                    {`Gesundheitszustand: Patienten können über die Tino DTB App täglich Ihren aktuellen
                  Gesundheitszustand dokumentieren.`}
                  </Text>
                </li>
                <li>
                  <Text>
                    {`Medikationseinnahme: Patienten können über die Tino DTB App die Einnahme/Nicht-Einnahme
                  ihrer Medikation nach den Vorgaben Ihres Therapieplans vermerken.`}
                  </Text>
                </li>
                <li>
                  <Text>
                    {`Vitalwerte: Patienten haben die Möglichkeit, über die Tino DTB App Veränderungen bei ihren
                  Vitalwerten zu erfassen.`}
                  </Text>
                </li>
                <li>
                  <Text>
                    {`Nebenwirkungen: Patienten haben die Möglichkeit, über die Tino DTB App ggf. auftretende
                  Nebenwirkungen tagesaktuell zu erfassen. `}
                  </Text>
                </li>
              </ol>
            </li>
          </ol>
          <Text className="display-block">
            {`Die dokumentierten Daten werden in Echtzeit mit dem medizinischen Betreuungsteam geteilt. Damit
          hat das Team immer einen Überblick über den aktuellen Gesundheitszustand, kann die Therapie besser
          einstellen und kritischen Verläufen vorbeugen.`}
          </Text>
        </Col>
        <Divider />
        <Row justify="space-between" className="wrapper-image-list">
          <Col span={4} className="margin-bottom-m">
            <img alt="CE Piktogramm" className="img-item" src={ceLogo} />
          </Col>
          <Col span={4} className="margin-bottom-m">
            <img
              alt="Handbuch Piktogramm"
              className="img-item"
              src={handbookLogo}
            />
          </Col>
          <Col span={6} className="margin-bottom-m">
            <Row>
              <Col flex="100px">
                <img alt="SN-Piktogramm" className="img-item" src={snLogo} />
              </Col>
              <Col flex="auto">
                <Text className="display-block">
                  Ver.: {process.env.REACT_APP_VERSION}
                  <br />
                  Build: 2.3
                  <br />
                  Date: {process.env.REACT_APP_RELEASEDATE}
                </Text>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} xl={8} className="margin-bottom-m">
            <Row>
              <Col flex="50px">
                <img
                  alt="Hersteller-Piktogramm"
                  className="img-item"
                  src={manufacturerLogo}
                />
              </Col>
              <Col flex="auto" style={{ width: 80, overflow: 'hidden' }}>
                <Text className="display-block">
                  DTB Gesellschaft für digitale Therapiebegleitung mbH
                  <br />
                  Otto-Schott-Str. 15
                  <br />
                  07745 Jena
                </Text>
                <br />
                <NavLink
                  style={{
                    marginTop: 5,
                    display: 'inline-block',
                    width: '100%'
                    // color: '#753055'
                  }}
                  className="display-block "
                  rel="noopener noreferrer"
                  target="_blank"
                  to={{
                    pathname: 'https://www.digitale-therapiebegleitung.de'
                  }}
                >
                  www.digitale-therapiebegleitung.de
                </NavLink>
                <Text
                  style={{ marginTop: 5, display: 'inline-block' }}
                  className="margin-right-s"
                >
                  E-Mail:
                </Text>
                <a
                  href="mailto:info@digitale-therapiebegleitung.de"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  info@digitale-therapiebegleitung.de
                </a>
                <br />
                <Text
                  style={{ marginTop: 5, display: 'inline-block' }}
                  className="display-block margin-top-s"
                >
                  Amtsgericht Jena, HRB 518202
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

export default About;
