import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { patientManagementClient } from '../../axios';
import { MedicationTherapyStore } from '../MedicationTherapyStore/MedicationTherapyStore';
import ICases from './models/ICases';
import { IPatientContentValues } from './models/IPatientContentValues';

export class PatientCareStore {
  // cases
  @observable
  private cases: ICases[] = [];
  @observable
  private casesLoaded: boolean = false;
  @observable
  private casesLoadedMore: boolean = false;
  @observable
  private casesLoadedMoreSkip: number = 20;
  @observable
  allCasesLoaded: boolean = false;

  // archive
  @observable
  private casesArchive: ICases[] = [];
  @observable
  public casesArchiveLoaded: boolean = false;
  @observable
  private casesArchiveFilter: string = 'DONE';

  // case
  @observable
  private selectedCase: any = null;
  @observable
  private caseLoaded: boolean = false;

  // other
  @observable
  public warn = [];
  @observable
  private searchCases: string = '';
  @observable
  private case: any = null;
  @observable
  private authors: any = null;
  @observable
  private patient: any = null;
  @observable
  private patientLoaded: boolean = false;

  // dependencies stores
  private medicationTherapyStore;

  constructor() {
    makeObservable(this);
    this.medicationTherapyStore = new MedicationTherapyStore();
  }

  /**
   * @description getter setter cases
   */
  @computed
  get getCases() {
    return this.cases;
  }

  @action.bound setCases = (data: any) => {
    // default filter config
    this.cases = this.sortDescending(data, 'updatedAt');
  };

  /**
   * @description getter setter cases loded
   */
  @computed
  get getCasesLoaded() {
    return this.casesLoaded;
  }

  @action.bound setCasesLoading = (value: boolean) => {
    this.casesLoaded = value;
  };

  @computed
  public get getAllCasesLoaded(): boolean {
    return this.allCasesLoaded;
  }

  @action
  public setAllCasesLoaded = (option: boolean): void => {
    this.allCasesLoaded = option;
  };

  /**
   * description getter setter cases archive
   */
  @computed
  get getCasesArchive() {
    return this.casesArchive;
  }

  /**
   * @description default filter config
   * @param data
   */
  @action.bound setCasesArchive = (data: any) => {
    this.casesArchive = this.sortDescending(data, 'updatedAt');
  };

  @computed
  get getCasesArchiveLoaded() {
    return this.casesArchiveLoaded;
  }

  @action.bound setCasesArchiveLoaded = (value: boolean) => {
    this.casesArchiveLoaded = value;
  };

  @computed
  get getCasesLoadedMore() {
    return this.casesLoadedMore;
  }

  @computed
  get getCasesArchiveFilter() {
    return this.casesArchiveFilter;
  }

  /**
   * description getter setter case
   */
  @computed
  get getCaseLoaded() {
    return this.caseLoaded;
  }
  // FIX ME todo new use getter to get case
  @computed
  get getCaseEntry() {
    return this.selectedCase;
  }

  @action
  setCaseEntry(data: any) {
    this.selectedCase = data;
  }

  @action
  setFilterTreatmentState(filter: any) {
    this.casesArchiveFilter = filter;
  }

  /**
   * @description get cases with subdata infos
   * @param searchValue
   * @returns
   */
  @action.bound
  public fetchCases = async (searchValue: string = ''): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases>(
        `/cases?relations[]=patient&take=20&skip=0&treatmentState=${'NOT_ASSIGNED'}&isDigitalCare=false` //&search=${searchValue}` //&treatmentState=${'IN_PROGRESS'}
      );
      //this.cases = res.data[0];
      const extendedCasesInfos: any = await this.loadCasesSubData(res.data[0]);
      this.setCases(extendedCasesInfos);
      this.setCasesLoading(true);
    } catch (e) {
      return e;
    }
  };

  /**
   * @description get archive cases with subdata infos
   * @param search
   * @returns
   */
  @action
  public fetchArchivedCases = async (search: string = ''): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases[]>(
        `/cases?take=20&skip=0&search=${search}&treatmentState=${this.casesArchiveFilter}`
      );
      this.casesArchive = res.data[0];
      const extendedCasesInfos: any = await this.loadCasesSubData(res.data[0]);
      this.setCasesArchive(extendedCasesInfos);
      this.setCasesArchiveLoaded(true);
      return res.data[0];
    } catch (e) {
      return e;
    }
  };

  @action loadCasesSubData = async (list: any): Promise<any> => {
    let entries: any = [];
    await Promise.all(
      await list.map(async (entry: any, index: number) => {
        let medPlans: any = null;
        let adherence: any = null;
        let counter: any = null;
        let medication: any = null;
        let alarmThresholdReview: any = [];

        // load current medicationplan
        medPlans = await this.medicationTherapyStore.fetchCurrentMedPlan(
          entry.patientId
        );

        // load medication in table
        adherence = await this.medicationTherapyStore.fetchMedicationInTake(
          entry.patientId
        );

        // load unread messages counter
        counter = await this.fetchUnreadMessageCount(entry.messageProcessId);

        let temp: any = null;
        let medicationData: any = null;
        // load side effects
        if (
          medPlans !== null &&
          medPlans.status === 200 &&
          !isEmpty(medPlans) &&
          medPlans !== 'undefined' &&
          medPlans.data[1] > 0
        ) {
          medication = await this.medicationTherapyStore.fetchSideEffectsSettings(
            entry.patientId,
            medPlans.data[0][0].id
          );
          const medication2 = await this.medicationTherapyStore.fetchVitalDataSettings(
            entry.patientId,
            medPlans.data[0][0].id
          );

          let data = await this.medicationTherapyStore.fetchAlarmtTresholdReviewDaySideEffects(
            medPlans.data[0][0].id,
            entry.patientId
          );

          alarmThresholdReview = data ? data : [];
        }

        // build case with obj and push in new array cases
        let item = {
          ...entry,
          adherence: adherence.hasOwnProperty('data') ? adherence?.data[0] : [],
          warnings:
            medPlans.status === 200 && medPlans.data[1] > 0
              ? medPlans.data[0][0].warnings
              : [],
          currentTherapyPlan:
            medPlans.status === 200 && medPlans.data[1] > 0
              ? medPlans.data[0][0]
              : [],
          medPlans: medPlans.status === 200 && medPlans.data[0],
          alarmThresholdReview: alarmThresholdReview,
          sideEffects:
            medication && medication.status === 200 ? medication.data : [],
          notificationsCount: counter !== undefined ? counter : 0,
          ...medication
        };

        entries.push(item);
      })
    );
    return entries;
  };

  @action getMoreCases = async (): Promise<any> => {
    if (this.casesLoadedMore) {
      return;
    } else {
      this.casesLoadedMore = true;
      const newData = await this.fetchMoreCases(
        20,
        this.casesLoadedMoreSkip,
        ''
      );
      const extendedCasesInfos: any = await this.loadCasesSubData(newData);
      const tempData = [...this.cases, ...extendedCasesInfos];

      setTimeout(() => {
        this.setCases(tempData);
      }, 200);

      this.casesLoadedMoreSkip = this.casesLoadedMoreSkip + 20;
      this.casesLoadedMore = false;
    }
    return;
  };

  @action.bound
  public fetchMoreCases = async (
    take: number = 20,
    skip: number = 0,
    searchValue: string = ''
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases>(
        `/cases?relations[]=patient&take=${take}&skip=${skip}&treatmentState=NOT_ASSIGNED&isDigitalCare=false&search=${searchValue}`
      );
      if (res.data[1] === 0) {
        this.allCasesLoaded = true;
      }
      return res.data[0];
    } catch (e) {
      return e;
    }
  };

  sortDescending(array: any, sortBy: string) {
    return array.sort(
      (a: any, b: any) => +moment(b[sortBy]) - +moment(a[sortBy])
    );
  }

  /**
   * @description get case with patient data infos
   * @param caseId
   * @returns
   */
  @action
  public fetchCase = async (caseId: string): Promise<any> => {
    if (this.caseLoaded) {
      return;
    }
    try {
      this.caseLoaded = true;
      const res: any = await patientManagementClient.get<any>(
        `/cases/${caseId}?relations[]=patient`
      );

      this.caseLoaded = false;
      this.setCaseEntry(res.data);

      /**
       * @description deprecated remove in v1.5.0
       */
      // load current med plans

      //const medPlans = await this.medicationTherapyStore.fetchCurrentMedPlan(
      //  res.data.patientId
      //);
      /*
      if (medPlans?.data?.[0]?.[0]?.id !== undefined) {
        const medi = await this.medicationTherapyStore.fetchMedication(
          medPlans.data[0][0].id
        );
        // load medication in tale
        const adherence = await this.medicationTherapyStore.fetchMedicationInTake(
          res.data.patientId
        );
      }
      */
      let tempData = {
        ...res.data
      };

      this.setCaseEntry({ ...this.selectedCase, ...res.data, ...tempData });
      this.case = res.data;

      return res.data;
    } catch (e) {
      return e;
    }
  };

  @action
  public fetchUnreadMessageCount = async (
    messageProcessId: string
  ): Promise<any> => {
    try {
      const { data }: any = await patientManagementClient.get<any>(
        `/process/${messageProcessId}`
      );
      return data ? data.orgUnreadMessages : '';
    } catch (e) {
      return e;
    }
  };

  /**
   * @description Update data from patient profile
   * @param param0
   * @returns
   */
  @action.bound async fetchPatientData({
    patientId
  }: {
    patientId: string;
  }): Promise<any> {
    if (this.patientLoaded) {
      return;
    }
    try {
      const { data }: any = await patientManagementClient.get<any>(
        `/patients/${patientId}/data`
      );
      this.patient = data[0];
      this.patientLoaded = true;
    } catch (e) {
      return e;
    }
  }

  /**
   * @description patch or post data patient
   * @param param0
   * @returns
   */
  @action.bound async patchPatientItem({
    patientId,
    contentValues
  }: {
    patientId: string;
    contentValues: IPatientContentValues;
  }): Promise<any> {
    try {
      const res: any = await patientManagementClient.patch<any>(
        `/patients/${patientId}`,
        {
          firstName: contentValues.firstName,
          lastName: contentValues.lastName,
          birthdayString: contentValues.birthdayString,
          phoneAccess: {
            phones: [
              { number: contentValues.phone, name: 'phone' },
              { number: contentValues.mobilephone, name: 'mobile' }
            ],
            type: 'PatientPhone'
          },
          insuranceCard: {
            insuranceName: contentValues.insuranceName,
            insuranceNumber: contentValues.insuranceNumber,
            insuranceType: contentValues.insuranceType,
            type: 'PatientInsurance'
          },
          address: {
            name: contentValues.firstName + ' ' + contentValues.lastName,
            address1: contentValues.homeAddress1,
            address2: contentValues.homeAddress2,
            zip: contentValues.homeZip,
            city: contentValues.homeCity,
            country: contentValues.homeCountry,
            type: 'PatientAddress'
          }
        }
      );
      const { data } = res;

      // Only set the results if defined otherwise we would change the initial
      if (data) {
        const { firstName, lastName, gender, birthdayString } = data;
        const updatedCase = {
          ...this.selectedCase,
          patient: data,
          patientFirstName: firstName,
          patientLastName: lastName,
          patientBirthdayString: birthdayString || null,
          patientGender: gender
        };
        this.selectedCase = updatedCase;
      }
      return res;
    } catch (e) {
      return false;
    }
  }

  @action
  updateCaseItemList = (warningIndex: number, caseIndex: any) => {
    let entry = { ...this.cases[caseIndex] };
    entry?.currentTherapyPlan.warnings.splice(warningIndex, 1);

    this.cases[caseIndex] = { ...entry };
    this.cases[caseIndex].currentTherapyPlan = { ...entry.currentTherapyPlan };
    this.cases[caseIndex].currentTherapyPlan.warnings = [
      ...entry.currentTherapyPlan.warnings
    ];
  };
}
