export default {
  therapyManager: 'Therapieplaner',
  profil: 'Profil',
  logout: 'Abmelden',
  about: 'Über Uns',
  imprint: 'Impressum',
  titlePatientCare: 'Patientenbetreuung',
  treatment: 'Behandlung',
  archive: 'Archiv'
};
